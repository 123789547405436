export const SwapIcon = () => <img src="/icons/bitcoin-convert.svg" alt="" width={20} height={20} />
export const SwapFillIcon = () => <img src="/icons/bitcoin-convert.svg" alt="" width={20} height={20} />

export const PollIcon = () => <img src="/icons/chart-square.svg" alt="" width={20} height={20} />
export const PollFillIcon = () => <img src="/icons/chart-square.svg" alt="" width={20} height={20} />

export const LiquidityIcon = () => <img src="/icons/trade.svg" alt="" width={20} height={20} />
export const LiquidityFillIcon = () => <img src="/icons/trade.svg" alt="" width={20} height={20} />

export const HoardIcon = () => <img src="/icons/coin.svg" alt="" width={20} height={20} />
export const HoardFillIcon = () => <img src="/icons/coin.svg" alt="" width={20} height={20} />
