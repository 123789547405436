import { styled } from "styled-components";
import { Flex } from "../Box";

const StyledBottomNav = styled(Flex)`
  height: 101px;
  width: 100%;
  // backdrop-filter: blur(40px);
  padding: 5px 8px;
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*="TokenPocket_iOS"] & {
    padding-bottom: 45px;
  }
  z-index: 20;
`;

export default StyledBottomNav;
