import React, { memo, useState } from "react";
import styled from "styled-components";
import BottomNavItem from "../BottomNavItem";
import { Flex } from "../Box";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { NotificationDot } from "../NotificationDot";
import { Overlay } from "../Overlay";
import StyledBottomNav from "./styles";
import { BottomNavProps } from "./types";

const HeaderShadow = styled.div`
  position: absolute;
  pointer-events: none;
  user-select: none;
  z-index: 19;
  transition: top 0.2s;
  left: 50%;
  transform: translateX(-50%);
`;
const BottomNav: React.FC<React.PropsWithChildren<BottomNavProps>> = ({
  items = [],
  activeItem = "",
  activeSubItem = "",
  ...props
}) => {
  const [menuOpenByIndex, setMenuOpenByIndex] = useState({});
  const isBottomMenuOpen = Object.values(menuOpenByIndex).some((acc) => acc);
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {isBottomMenuOpen && <Overlay />}
      <div style={{ position: "fixed", bottom: "0", overflow: "hidden", width: "100%", backdropFilter: "blur(40px)" }}>
        <HeaderShadow>
          <img
            src="/images/header-shadow.png"
            alt=""
            style={{
              minWidth: "500px",
            }}
          />
        </HeaderShadow>
        <StyledBottomNav justifyContent="space-around" {...props}>
          {items
            .filter((item) => item.label)
            .map(
              (
                {
                  label,
                  items: menuItems,
                  href,
                  isOpenBlank,
                  icon,
                  fillIcon,
                  showOnMobile = true,
                  showItemsOnMobile = true,
                  disabled,
                },
                index
              ) => {
                const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
                return (
                  showOnMobile && (
                    <DropdownMenu
                      key={`${label}#${href}`}
                      items={menuItems}
                      isBottomNav
                      activeItem={activeSubItem}
                      showItemsOnMobile={showItemsOnMobile}
                      setMenuOpenByIndex={setMenuOpenByIndex}
                      index={index}
                      isDisabled={disabled}
                    >
                      <Flex style={{}}>
                        <NotificationDot show={!!statusColor} color={statusColor}>
                          <BottomNavItem
                            href={href}
                            disabled={disabled}
                            isActive={href === activeItem}
                            isBlank={isOpenBlank}
                            label={label}
                            icon={icon}
                            fillIcon={fillIcon}
                            showItemsOnMobile={showItemsOnMobile}
                          />
                        </NotificationDot>
                      </Flex>
                    </DropdownMenu>
                  )
                );
              }
            )}
        </StyledBottomNav>
      </div>
    </div>
  );
};

export default memo(BottomNav);
