import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992ZM7.99992 5.16658C7.5857 5.16658 7.24992 5.50237 7.24992 5.91658C7.24992 6.19273 7.02606 6.41658 6.74992 6.41658C6.47378 6.41658 6.24992 6.19273 6.24992 5.91658C6.24992 4.95009 7.03342 4.16659 7.99992 4.16659C8.96642 4.16659 9.74992 4.95009 9.74992 5.91658C9.74992 6.39048 9.56089 6.82124 9.25522 7.13589C9.19371 7.19921 9.13504 7.25783 9.07907 7.31376C8.93518 7.45752 8.80912 7.58347 8.69852 7.72558C8.5525 7.9132 8.49992 8.05109 8.49992 8.16658V8.66658C8.49992 8.94273 8.27606 9.16658 7.99992 9.16658C7.72378 9.16658 7.49992 8.94273 7.49992 8.66658V8.16658C7.49992 7.72979 7.70326 7.37621 7.90936 7.11139C8.06186 6.91545 8.25354 6.72415 8.4091 6.56889C8.45603 6.52206 8.49967 6.47851 8.53795 6.4391C8.66964 6.30354 8.74992 6.11995 8.74992 5.91658C8.74992 5.50237 8.41413 5.16658 7.99992 5.16658ZM7.99992 11.3333C8.36811 11.3333 8.66658 11.0348 8.66658 10.6666C8.66658 10.2984 8.36811 9.99992 7.99992 9.99992C7.63173 9.99992 7.33325 10.2984 7.33325 10.6666C7.33325 11.0348 7.63173 11.3333 7.99992 11.3333Z"
        fill="url(#paint0_linear_57_4178)"
        fill-opacity="0.15"
      />
      <defs>
        <linearGradient
          id="paint0_linear_57_4178"
          x1="7.99992"
          y1="1.33325"
          x2="7.99992"
          y2="14.6666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ADCBF3" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
