import React, { useContext } from "react";
import { baseColors } from "../../tokens";
import { MenuContext } from "../../widgets/Menu/context";
import { Flex } from "../Box";
import AnimatedIconComponent from "../Svg/AnimatedIconComponent";
import { StyledBottomNavItem, StyledBottomNavText } from "./styles";
import { BottomNavItemProps } from "./types";

const BottomNavItem: React.FC<React.PropsWithChildren<BottomNavItemProps>> = ({
  label,
  icon,
  fillIcon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  disabled = false,
  isBlank = false,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext);
  const bottomNavItemContent = (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        height: "fit-content",
      }}
    >
      {icon && (
        <div
          style={{
            marginBottom: "4px",
            background: isActive ? "rgba(173, 203, 243, 0.25)" : baseColors.background,
            padding: "10px",
            border: `1px solid ${baseColors.cardBorder1}`,
            borderRadius: "50px",
          }}
        >
          <AnimatedIconComponent
            icon={icon}
            fillIcon={fillIcon}
            height="20px"
            width="20px"
            color={isActive ? "secondary" : "textSubtle"}
            isActive={isActive}
          />
        </div>
      )}
      <StyledBottomNavText
        color={isActive ? "text" : "textSubtle"}
        fontWeight={isActive ? "600" : "400"}
        fontSize="10px"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {label}
      </StyledBottomNavText>
    </Flex>
  );

  return showItemsOnMobile ? (
    <StyledBottomNavItem style={{ opacity: disabled ? 0.5 : 1 }} type="button" {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem
      style={{ opacity: disabled ? 0.5 : 1 }}
      target={isBlank ? "_blank" : undefined} // Add target attribute based on isBlank
      rel={isBlank ? "noopener noreferrer" : undefined} // Add rel attribute for security when target="_blank"
      as={linkComponent}
      href={href}
      {...props}
    >
      {bottomNavItemContent}
    </StyledBottomNavItem>
  );
};

export default BottomNavItem;
