/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
import { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'

interface SpotlightLoader {
  isLoadingGlobal?: boolean
  isWaitingData?: boolean
  children?: ReactNode
}

export default function SpotlightLoader({ isLoadingGlobal = true, isWaitingData, children }: SpotlightLoader) {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isLoadingGlobal) {
      const timeoutId = setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.style.display = 'none'
        }
      }, 1500)
      return () => clearTimeout(timeoutId)
    }
    if (containerRef.current && isWaitingData) {
      containerRef.current.style.display = 'block'
    }
    if (containerRef.current && !isWaitingData) {
      containerRef.current.style.display = 'none'
    }
  }, [isWaitingData])

  return isLoadingGlobal ? (
    <WrapperGlobal ref={containerRef}>
      <div className="loading">
        <img width={300} src="/images/loading-icon.png" />
      </div>
    </WrapperGlobal>
  ) : (
    <Wrapper>
      <div className="loadingWrap" ref={containerRef}>
        <div className="loading">
          <img width={300} src="/images/loading-icon.png" />
        </div>
      </div>
      {children}
    </Wrapper>
  )
}

const WrapperGlobal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100svh;
  z-index: 1000;
  background-color: #000000;
  backdrop-filter: blur(40px);

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .loadingWrap {
    position: fixed;
    width: 100%;
    height: 500px;
    z-index: 1000;
    background-color: #00000039;
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    .loadingWrap {
      position: fixed;
      max-width: 1200px;
      /* width: 100%; */
      height: 500px;
      z-index: 1000;
      background-color: #00000039;
      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`
