export const baseColors = {
  white: "white",
  failure: "#FFD012",
  failure33: "#ED4B9E33",
  primary: "#ADCBF3",
  primary1: "rgba(151, 161, 192, 1)",
  primary0f: "#1FC7D40f",
  primary3D: "#1FC7D43D",
  primaryBright: "#53DEE9",
  primaryDark: "#0098A1",
  success: "#31D0AA",
  success19: "#31D0AA19",
  warning: "rgba(173, 203, 243, 0.15)",
  warning2D: "#ED4B9E2D",
  warning33: "#ED4B9E33",
  dark: "#131313",
  backgroundGradient:
    "radial-gradient(63.86% 63.86% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%)",
  cardBorder1: "rgba(173, 203, 243, 0.1)",
  backgroundButtonGradient: "linear-gradient(180deg, rgba(173, 203, 243, 0.1) , rgba(173, 203, 243, 0.2) )",
  background: "rgba(173, 203, 243, 0.1)",
};

export const additionalColors = {
  binance: "#F0B90B",
  overlay: "#452a7a",
  gold: "#FFC700",
  silver: "#B2B2B2",
  bronze: "#E7974D",
  yellow: "#D67E0A",
};

export const lightColors = {
  ...baseColors,
  ...additionalColors,
  secondary: "#ADCBF3",
  secondary80: "#A881FC80",
  background: "#08060B",
  backgroundDisabled: "#3c3742",
  backgroundAlt: "#1b1b1b",
  backgroundAlt2: "rgba(39, 38, 44, 0.7)",
  backgroundGradient:
    "radial-gradient(63.86% 63.86% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%)",
  cardBorder: "#433910",
  cardBorder1: "rgba(173, 203, 243, 0.16)",
  contrast: "#020202",
  dropdown: "#1E1D20",
  dropdownDeep: "#100C18",
  invertedContrast: "#FFD012",
  input: "#1b1b1b",
  inputSecondary: "#ADCBF3",
  primaryDark: "#0098A1",
  tertiary: "#353547",
  text: "#F4EEFF",
  text99: "#F4EEFF99",
  textDisabled: "#666171",
  textSubtle: "#F4EEFF99",
  disabled: "#524B63",
  gradientPrimary: "linear-gradient(228.54deg, rgb(252, 114, 255) -13.69%, #9A6AFF 91.33%)",
  gradientBubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
  gradientInverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
  gradientCardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
  gradientBlue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
  gradientViolet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
  gradientVioletAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
  gradientGold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  gradientBold: "linear-gradient(#53DEE9, #9A6AFF)",
};

export const darkColors = {
  ...baseColors,
  ...additionalColors,
  secondary: "#ADCBF3",
  secondary80: "#A881FC80",
  background: "#08060B",
  backgroundDisabled: "#3c3742",
  backgroundAlt: "#1b1b1b",
  backgroundAlt2: "rgba(39, 38, 44, 0.7)",
  backgroundGradient:
    "radial-gradient(63.86% 63.86% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%)",
  cardBorder: "#7B8DA8",
  cardBorder1: "rgba(173, 203, 243, 0.16)",
  contrast: "#020202",
  dropdown: "#1E1D20",
  dropdownDeep: "#100C18",
  invertedContrast: "#FFD012",
  input: "#1b1b1b",
  inputSecondary: "#262130",
  primaryDark: "#0098A1",
  tertiary: "#353547",
  text: "#F4EEFF",
  text99: "#F4EEFF99",
  textDisabled: "#666171",
  textSubtle: "#F4EEFF99",
  disabled: "#524B63",
  gradientPrimary: "linear-gradient(228.54deg, rgb(252, 114, 255) -13.69%, #9A6AFF 91.33%)",
  gradientBubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
  gradientInverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
  gradientCardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
  gradientBlue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
  gradientViolet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
  gradientVioletAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
  gradientGold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  gradientBold: "linear-gradient(#53DEE9, #9A6AFF)",
};
