import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.46436 6.46448L13.5354 13.5355M6.46436 13.5355L13.5354 6.46448"
        stroke="url(#paint0_linear_57_4145)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_57_4145"
          x1="9.49045"
          y1="6.46448"
          x2="9.49045"
          y2="13.5355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ADCBF3" />
          <stop offset="1" stop-color="#DAE9FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
