import React, { MouseEvent } from "react";
import { styled } from "styled-components";
import { MotionBox } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import { IconButton } from "../../components/Button";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { ModalProps } from "./types";

export const mobileFooterHeight = 73;

export const ModalHeader = styled(Flex)<{ background?: string; headerBorderColor?: string; isTitle?: boolean }>`
  align-items: center;
  background: transparent;
  display: flex;
  padding: 24px 24px;
  position: relative;

  &::after {
    position: absolute;
    height: 1px;
    content: "";
    bottom: 0;
    left: 24px;
    right: 24px;
    background: ${({ isTitle }) => (isTitle ? "rgba(173, 203, 243, 0.05)" : "transparent")};
    border-radius: 14px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    /* background: ${({ background }) => background || "transparent"}; */
  }
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
  font-family: "Golos Text";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  background: linear-gradient(180deg, #adcbf3 0%, #dae9fd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - ${mobileFooterHeight}px);
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    max-height: 90vh;
  }
`;

export const WrapperCloseButton = styled.div`
  width: 40px;
  height: 40px;

  background: rgba(173, 203, 243, 0.05);
  border: 1px solid rgba(173, 203, 243, 0.05);
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 46px;
    height: 46px;
  }
`;

export const ModalCloseButton: React.FC<React.PropsWithChildren<{ onDismiss: ModalProps["onDismiss"] }>> = ({
  onDismiss,
}) => {
  return (
    <WrapperCloseButton>
      <IconButton
        variant="text"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onDismiss?.();
        }}
        aria-label="Close the dialog"
      >
        <CloseIcon color="primary" />
      </IconButton>
    </WrapperCloseButton>
  );
};

export const ModalBackButton: React.FC<React.PropsWithChildren<{ onBack: ModalProps["onBack"] }>> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="primary" />
    </IconButton>
  );
};

export const ModalContainer = styled(MotionBox)<{
  $minHeight: string;
}>`
  overflow: hidden;
  background: rgba(173, 203, 243, 0.06);
  border-radius: 20px;

  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: absolute;
  bottom: 0;
  max-width: none !important;
  min-height: ${({ $minHeight }) => $minHeight};

  ${({ theme }) => theme.mediaQueries.md} {
    width: auto;
    position: auto;
    bottom: auto;
    max-height: 100vh;
  }

  .container-modal {
    background: radial-gradient(50% 44.1% at 50% 55.9%, rgba(173, 203, 243, 0) 0%, rgba(173, 203, 243, 0.05) 100%);
    border-radius: 14px;
    margin: 12px;
  }
` as typeof MotionBox;
